/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { Headers, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'
import * as AppBskySplxCollectible from './collectible'

export interface QueryParams {}

export interface InputSchema {
  /** Stringified JSON of attributes of collectible */
  attributes: string
  creator?: string
  collectionId?: string
  description: string
  imageCid: string
  image: AppBskySplxCollectible.Image
  projectId?: string
  supply: number
  title: string
  wallet: string
  isPaid: boolean
  animationUrl?: string
  [k: string]: unknown
}

export interface OutputSchema {
  /** CollectionId from v1 */
  collectionId: string
  uri: string
  cid: string
  [k: string]: unknown
}

export interface CallOptions {
  headers?: Headers
  qp?: QueryParams
  encoding: 'application/json'
}

export interface Response {
  success: boolean
  headers: Headers
  data: OutputSchema
}

export function toKnownErr(e: any) {
  if (e instanceof XRPCError) {
  }
  return e
}

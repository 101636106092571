/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface Main {
  collectible: Collectible
  [k: string]: unknown
}

export function isMain(v: unknown): v is Main {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'app.bsky.embed.collectible#main' ||
      v.$type === 'app.bsky.embed.collectible')
  )
}

export function validateMain(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.collectible#main', v)
}

export interface Collectible {
  collUri: string
  collCid: string
  [k: string]: unknown
}

export function isCollectible(v: unknown): v is Collectible {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.collectible#collectible'
  )
}

export function validateCollectible(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.collectible#collectible', v)
}

export interface View {
  collectible: ViewCollectible
  [k: string]: unknown
}

export function isView(v: unknown): v is View {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.collectible#view'
  )
}

export function validateView(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.collectible#view', v)
}

export interface ViewCollectible {
  /** Stringified JSON of attributes of the collection */
  collectibleAttributes: string
  collectibleDescription: string
  collectibleImageUri: string
  collectiblePrice?: number
  collectibleCurrency?: string
  collectibleSupply: number
  collectibleTitle: string
  collectibleWallet: string
  collectibleUri: string
  collectibleCid: string
  collectibleCreator?: string
  collectibleCreatedAt?: string
  collectibleIndexedAt?: string
  collectibleSortAt?: string
  collectibleCollectionId?: string
  collectibleProjectId?: number
  collectibleAnimationUrl?: string
  pecUri?: string
  pecCid?: string
  aggUpdatedAt?: string
  aggMinted?: number
  aggPending?: number
  [k: string]: unknown
}

export function isViewCollectible(v: unknown): v is ViewCollectible {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.collectible#viewCollectible'
  )
}

export function validateViewCollectible(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.collectible#viewCollectible', v)
}

/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { Headers, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'
import * as ComAtprotoRepoStrongRef from '../../../com/atproto/repo/strongRef'

export interface QueryParams {}

export interface InputSchema {
  /** The uri of the collectible */
  collectibleUri?: string
  collectibleCid: string
  /** formatted timestamp gotten back from sphere when requesting a payment */
  createdAt: string
  /** The id returned from Sphere that is unique to this payment */
  paymentId: string
  /** Base-64 encoded transaction */
  serializedTxn: string
  /** cid of paymentCollection */
  subjectCid?: string
  /** cid of paymentCollection */
  wallet: string
  postSubject?: ComAtprotoRepoStrongRef.Main
  [k: string]: unknown
}

export interface OutputSchema {
  /** Txn Id for sent txn */
  transactionId: string
  [k: string]: unknown
}

export interface CallOptions {
  headers?: Headers
  qp?: QueryParams
  encoding: 'application/json'
}

export interface Response {
  success: boolean
  headers: Headers
  data: OutputSchema
}

export function toKnownErr(e: any) {
  if (e instanceof XRPCError) {
  }
  return e
}

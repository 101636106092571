/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface Main {
  frame: Frame
  [k: string]: unknown
}

export function isMain(v: unknown): v is Main {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'app.bsky.embed.frame#main' ||
      v.$type === 'app.bsky.embed.frame')
  )
}

export function validateMain(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.frame#main', v)
}

export interface Frame {
  uri: string
  title: string
  description?: string
  image?: string
  buttons?: FrameButtonMetadata[]
  input?: FrameInputMetadata
  post_url?: string
  refresh_period?: number
  version?: string
  [k: string]: unknown
}

export function isFrame(v: unknown): v is Frame {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'app.bsky.embed.frame#frame'
  )
}

export function validateFrame(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.frame#frame', v)
}

export interface FrameButtonMetadata {
  label: string
  action?: 'post' | 'post_redirect' | 'txn' | 'link' | 'share'
  index?: number
  post_url?: string
  text?: string
  [k: string]: unknown
}

export function isFrameButtonMetadata(v: unknown): v is FrameButtonMetadata {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.frame#frameButtonMetadata'
  )
}

export function validateFrameButtonMetadata(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.frame#frameButtonMetadata', v)
}

export interface FrameInputMetadata {
  text: string
  [k: string]: unknown
}

export function isFrameInputMetadata(v: unknown): v is FrameInputMetadata {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.frame#frameInputMetadata'
  )
}

export function validateFrameInputMetadata(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.frame#frameInputMetadata', v)
}

export interface View {
  frame: ViewFrame
  [k: string]: unknown
}

export function isView(v: unknown): v is View {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'app.bsky.embed.frame#view'
  )
}

export function validateView(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.frame#view', v)
}

export interface ViewFrame {
  uri: string
  title: string
  description: string
  image?: string
  buttons?: ViewFrameButtonMetadata[]
  input?: ViewFrameInputMetadata
  post_url?: string
  refresh_period?: number
  version?: string
  [k: string]: unknown
}

export function isViewFrame(v: unknown): v is ViewFrame {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.frame#viewFrame'
  )
}

export function validateViewFrame(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.frame#viewFrame', v)
}

export interface ViewFrameButtonMetadata {
  label: string
  action?: 'post' | 'post_redirect' | 'txn' | 'link' | 'share'
  index?: number
  post_url?: string
  text?: string
  [k: string]: unknown
}

export function isViewFrameButtonMetadata(
  v: unknown,
): v is ViewFrameButtonMetadata {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.frame#viewFrameButtonMetadata'
  )
}

export function validateViewFrameButtonMetadata(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.frame#viewFrameButtonMetadata', v)
}

export interface ViewFrameInputMetadata {
  text: string
  [k: string]: unknown
}

export function isViewFrameInputMetadata(
  v: unknown,
): v is ViewFrameInputMetadata {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.frame#viewFrameInputMetadata'
  )
}

export function validateViewFrameInputMetadata(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.frame#viewFrameInputMetadata', v)
}

/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface Main {
  videos: Video[]
  [k: string]: unknown
}

export function isMain(v: unknown): v is Main {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'app.bsky.embed.videos#main' ||
      v.$type === 'app.bsky.embed.videos')
  )
}

export function validateMain(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.videos#main', v)
}

export interface Video {
  /** The URI pointing to the video's location on IPFS or Arweave */
  uri: string
  alt: string
  /** Duration of the video in seconds */
  duration?: number
  aspectRatio?: AspectRatio
  [k: string]: unknown
}

export function isVideo(v: unknown): v is Video {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'app.bsky.embed.videos#video'
  )
}

export function validateVideo(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.videos#video', v)
}

/** width:height represents an aspect ratio. It may be approximate, and may not correspond to absolute dimensions in any given unit. */
export interface AspectRatio {
  width: number
  height: number
  [k: string]: unknown
}

export function isAspectRatio(v: unknown): v is AspectRatio {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.videos#aspectRatio'
  )
}

export function validateAspectRatio(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.videos#aspectRatio', v)
}

export interface View {
  videos: ViewVideo[]
  [k: string]: unknown
}

export function isView(v: unknown): v is View {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'app.bsky.embed.videos#view'
  )
}

export function validateView(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.videos#view', v)
}

export interface ViewVideo {
  /** The URI pointing to the video's location on IPFS or Arweave */
  uri: string
  alt?: string
  /** Duration of the video in seconds */
  duration?: number
  aspectRatio?: AspectRatio
  [k: string]: unknown
}

export function isViewVideo(v: unknown): v is ViewVideo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.videos#viewVideo'
  )
}

export function validateViewVideo(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.videos#viewVideo', v)
}

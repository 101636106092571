/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'
import * as AppBskyActorDefs from '../actor/defs'

export interface ClaimViewBasic {
  actionId?: string
  uri?: string
  cid?: string
  /** Is a datetime, but may be empty string. */
  claimedAt?: string
  createdAt?: string
  creator?: string
  subjectCid?: string
  subject?: string
  wallet?: string
  [k: string]: unknown
}

export function isClaimViewBasic(v: unknown): v is ClaimViewBasic {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.defs#claimViewBasic'
  )
}

export function validateClaimViewBasic(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.defs#claimViewBasic', v)
}

export interface ClaimViewDetailed {
  actionId: string
  uri?: string
  cid?: string
  claimedAt?: string
  createdAt?: string
  indexedAt?: string
  subject?: string
  subjectCid?: string
  creator?: AppBskyActorDefs.ProfileViewBasic
  collectible?: CollectibleViewBasic
  [k: string]: unknown
}

export function isClaimViewDetailed(v: unknown): v is ClaimViewDetailed {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.defs#claimViewDetailed'
  )
}

export function validateClaimViewDetailed(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.defs#claimViewDetailed', v)
}

export interface CollectibleViewBasic {
  uri?: string
  cid: string
  creator?: string
  createdAt?: string
  sortAt?: string
  attributes?: string
  collectionId?: string
  description?: string
  imageUri?: string
  supply?: number
  title?: string
  [k: string]: unknown
}

export function isCollectibleViewBasic(v: unknown): v is CollectibleViewBasic {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.defs#collectibleViewBasic'
  )
}

export function validateCollectibleViewBasic(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.defs#collectibleViewBasic', v)
}

export interface CollectibleViewDetailed {
  uri?: string
  cid: string
  creator?: string
  createdAt?: string
  sortAt?: string
  attributes?: string
  collectionId?: string
  description?: string
  imageUri?: string
  supply?: number
  title?: string
  minted?: number
  pending?: number
  updatedAt?: string
  price?: number
  currency?: string
  [k: string]: unknown
}

export function isCollectibleViewDetailed(
  v: unknown,
): v is CollectibleViewDetailed {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.defs#collectibleViewDetailed'
  )
}

export function validateCollectibleViewDetailed(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.defs#collectibleViewDetailed', v)
}

export interface PaymentCollection {
  uri: string
  cid: string
  /** json for the attributes used to create the third party integration. */
  input?: string
  /** Values can be: sphere */
  paymentType?: string
  /** cid of collectible for this payment */
  subjectCid?: string
  /** destination wallet for payouts */
  wallet?: string
  /** price of the payment */
  price?: number
  /** currency of the payment */
  currency?: string
  /** json for the attributes returned from the third party integration. */
  output?: string
  createdAt?: string
  updatedAt?: string
  [k: string]: unknown
}

export function isPaymentCollection(v: unknown): v is PaymentCollection {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.defs#paymentCollection'
  )
}

export function validatePaymentCollection(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.defs#paymentCollection', v)
}

export interface TopMintersView {
  did?: string
  mints?: number
  [k: string]: unknown
}

export function isTopMintersView(v: unknown): v is TopMintersView {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.defs#topMintersView'
  )
}

export function validateTopMintersView(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.defs#topMintersView', v)
}

export interface FrameObject {
  version: string
  imageUrl: string
  postUrl: string
  buttons: FrameButton[]
  [k: string]: unknown
}

export function isFrameObject(v: unknown): v is FrameObject {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.defs#frameObject'
  )
}

export function validateFrameObject(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.defs#frameObject', v)
}

export interface FrameButton {
  index: number
  title: string
  type: string
  [k: string]: unknown
}

export function isFrameButton(v: unknown): v is FrameButton {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.defs#frameButton'
  )
}

export function validateFrameButton(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.defs#frameButton', v)
}

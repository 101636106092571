/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface Main {
  nft: Nft
  [k: string]: unknown
}

export function isMain(v: unknown): v is Main {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'app.bsky.embed.nft#main' || v.$type === 'app.bsky.embed.nft')
  )
}

export function validateMain(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.nft#main', v)
}

export interface Nft {
  name: string
  description: string
  image: BlobRef
  mintId: string
  aspectRatio?: AspectRatio
  [k: string]: unknown
}

export function isNft(v: unknown): v is Nft {
  return isObj(v) && hasProp(v, '$type') && v.$type === 'app.bsky.embed.nft#nft'
}

export function validateNft(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.nft#nft', v)
}

/** width:height represents an aspect ratio. It may be approximate, and may not correspond to absolute dimensions in any given unit. */
export interface AspectRatio {
  width: number
  height: number
  [k: string]: unknown
}

export function isAspectRatio(v: unknown): v is AspectRatio {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.embed.nft#aspectRatio'
  )
}

export function validateAspectRatio(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.nft#aspectRatio', v)
}

export interface View {
  nft: ViewNft
  [k: string]: unknown
}

export function isView(v: unknown): v is View {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'app.bsky.embed.nft#view'
  )
}

export function validateView(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.nft#view', v)
}

export interface ViewNft {
  name: string
  description: string
  image: string
  mintId: string
  [k: string]: unknown
}

export function isViewNft(v: unknown): v is ViewNft {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'app.bsky.embed.nft#viewNft'
  )
}

export function validateViewNft(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.embed.nft#viewNft', v)
}

/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface Record {
  /** The v1 actionId of the claim */
  actionId: string
  /** Seconds in UTC claim action went through */
  timeSec?: number
  /** The transactionId so we can create a sol scan / whatever link */
  txId?: string
  /** The wallet address the collectible was sent to */
  wallet?: string
  [k: string]: unknown
}

export function isRecord(v: unknown): v is Record {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'app.bsky.splx.postClaim#main' ||
      v.$type === 'app.bsky.splx.postClaim')
  )
}

export function validateRecord(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.postClaim#main', v)
}

/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface Record {
  /** Stringifed json for the attributes used to create the third party integration. */
  input?: string
  /** Values can be: sphere */
  paymentType: string
  /** cid of collectible for this payment */
  subjectCid: string
  /** destination wallet for payouts */
  wallet: string
  /** price of the payment */
  price: number
  /** currency of the payment */
  currency: string
  /** Stringifed json for the attributes returned from the third party integration. */
  output: string
  createdAt?: string
  updatedAt?: string
  [k: string]: unknown
}

export function isRecord(v: unknown): v is Record {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'app.bsky.splx.paymentCollection#main' ||
      v.$type === 'app.bsky.splx.paymentCollection')
  )
}

export function validateRecord(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.paymentCollection#main', v)
}

/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface Record {
  /** Stringified JSON of attributes of collectible */
  attributes: string
  creator: string
  createdAt?: string
  updatedAt?: string
  collectionId: string
  description: string
  image: Image
  imageCid: string
  projectId: number
  supply: number
  title: string
  wallet: string
  animationUrl?: string
  [k: string]: unknown
}

export function isRecord(v: unknown): v is Record {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'app.bsky.splx.collectible#main' ||
      v.$type === 'app.bsky.splx.collectible')
  )
}

export function validateRecord(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.collectible#main', v)
}

export interface Image {
  image: BlobRef
  alt: string
  aspectRatio?: AspectRatio
  [k: string]: unknown
}

export function isImage(v: unknown): v is Image {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.collectible#image'
  )
}

export function validateImage(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.collectible#image', v)
}

/** width:height represents an aspect ratio. It may be approximate, and may not correspond to absolute dimensions in any given unit. */
export interface AspectRatio {
  width: number
  height: number
  [k: string]: unknown
}

export function isAspectRatio(v: unknown): v is AspectRatio {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'app.bsky.splx.collectible#aspectRatio'
  )
}

export function validateAspectRatio(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.splx.collectible#aspectRatio', v)
}
